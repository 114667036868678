import { Observable } from 'rxjs';
import { SeriesColumnOptions } from 'highcharts';
import { HeatmapData } from '../graph-heatmap/graph-heatmap.component';
import { GridData } from '../graph-util.service';
import { SelectGroup, SelectOption } from '../../../core/models/ui/select-option.model';
import { Component, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ColorPaletteID } from 'src/app/core/color/color-palette.model';
import {
  TreeGraphColoringMetadataConfig,
  TreeGraphHeatmapConfig,
  TreeGraphLegendConfig,
  TreeGraphDefaultTooltips,
  TreeGraphTipLabelConfig,
} from '../graph-circular-tree/graph-circular-tree.model';

interface BaseGraphSidebarControl<DefaultOption> {
  type: GraphControlTypeEnum;
  name: string;
  label: string;
  disabled?: boolean;
  tooltip?: string;
  section?: string;
  defaultOption: DefaultOption;
}

export interface GraphSidebarSelectControl extends BaseGraphSidebarControl<string | number> {
  type: GraphControlTypeEnum.SELECT;
  options?: SelectOption<string | number>[] | SelectGroup<string | number>[];
  layout?: 'block' | 'inline';
}

export interface GraphSidebarCheckboxControl extends BaseGraphSidebarControl<boolean> {
  type: GraphControlTypeEnum.CHECKBOX;
}

export interface GraphSidebarInputControl extends BaseGraphSidebarControl<string | number> {
  type: GraphControlTypeEnum.INPUT;
  valueType: 'string' | 'number';
  layout?: 'block' | 'inline';
}

export interface GraphSidebarComponentControl extends BaseGraphSidebarControl<any> {
  type: GraphControlTypeEnum.COMPONENT;
  layout?: 'block' | 'inline';
  component: Component;
  injector: (form: FormControl) => Injector;
}

export interface GraphSidebarColorPaletteControl extends BaseGraphSidebarControl<ColorPaletteID> {
  type: GraphControlTypeEnum.PALETTE;
  /** Whether this metadata contains categorical data. If false, it is assumed to be numeric. */
  isCategorical: boolean;
  /** The number of unique values for this metadata field. Can be null if the data is not categorical. */
  numCategories: number | null;
  layout?: 'block' | 'inline';
}

export enum GraphControlTypeEnum {
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  INPUT = 'input',
  COMPONENT = 'component',
  PALETTE = 'palette',
}

export type GraphSidebarControl = { hidden?: boolean } & (
  | GraphSidebarSelectControl
  | GraphSidebarCheckboxControl
  | GraphSidebarInputControl
  | GraphSidebarComponentControl
  | GraphSidebarColorPaletteControl
);

export type GraphSidebarControls = GraphSidebarControl[];

type Async<T> = Observable<T> | Promise<T>;

export interface GraphDatasource {
  // should return an observable that emits a unique identifier for the graph.
  // (based on e.g. the document id and graph type)
  // which can be used to save graph sidebar options as user settings
  getIdForSavedOptions$(): Observable<{ id: string } | null>;
  setInitialOptions(initialOptions: Record<string, any>): void;
  init(): Async<GraphSidebarDatasourceResponse>;
  controlValueChanged(
    previousValues: unknown | undefined,
    currentValues: unknown,
  ): Async<GraphSidebarDatasourceResponse>;
  validate(): GraphDatasourceError | null;
}

export interface GraphSidebarDatasourceControls {
  controls: GraphSidebarControls;
}

export interface GraphSidebarDatasourceData {
  graph: ColumnGraphOptions | StackedColumnGraphOptions | HeatmapOptions | TreeGraphOptions;
  options: { [key: string]: any };
}

export interface GraphSidebarDatasourceError {
  error: string;
}

export interface GraphSidebarDatasourceReIndexingRequired {
  documentID: string;
  reIndexingRequiredTables: string[];
  options: { [key: string]: any };
}

export type GraphDatasourceReIndexingRequired = GraphSidebarDatasourceControls &
  GraphSidebarDatasourceReIndexingRequired;

export type GraphDatasourceError = GraphSidebarDatasourceControls & GraphSidebarDatasourceError;

export type GraphDatasourceData = GraphSidebarDatasourceControls & GraphSidebarDatasourceData;

export type GraphSidebarDatasourceResponse =
  | GraphDatasourceData
  | GraphDatasourceError
  | GraphDatasourceReIndexingRequired;

export function isDatasourceErrorResponse(
  response: GraphSidebarDatasourceResponse,
): response is GraphDatasourceError {
  return 'error' in response;
}

export function isGraphSidebarDatasourceReIndexingRequired(
  response: GraphSidebarDatasourceResponse,
): response is GraphDatasourceReIndexingRequired {
  return 'reIndexingRequiredTables' in response;
}

interface GraphOptions extends GraphTitleAndAxes {
  type: GraphTypeEnum;
  animations?: boolean;
  hideSidebar?: boolean;
}

export interface GraphTitleAndAxes {
  title: string;
  yAxisTitle: string;
  xAxisTitle: string;
}

export interface StackedColumnGraphOptions extends GraphOptions {
  data: SeriesColumnOptions[];
  stacking: 'percent' | 'normal';
  yAxisRange?: { min: number; max: number };
  showLabels: boolean;
  showLegend: boolean;
  type: GraphTypeEnum.STACKED_COLUMN;
}

export interface ColumnGraphOptions extends GraphOptions {
  data: SeriesColumnOptions[];
  type: GraphTypeEnum.COLUMN;
}

export interface HeatmapGraphOptions extends GraphOptions {
  data: HeatmapData;
  wrapped?: boolean;
  type: GraphTypeEnum.HEATMAP;
  reduced?: boolean;
  transposed?: boolean;
}

export interface HeatmapTableOptions {
  data: GridData;
  type: GraphTypeEnum.HEATMAP_TABLE;
}

export type HeatmapOptions = HeatmapGraphOptions | HeatmapTableOptions;

export interface TreeGraphData {
  id: string;
  branch_length: number;
  children: TreeGraphData[];
}

export interface TreeGraphOptions {
  data: TreeGraphData;
  tipLabelConfig: TreeGraphTipLabelConfig;
  coloringMetadataConfig: TreeGraphColoringMetadataConfig;
  legendConfig: TreeGraphLegendConfig;
  heatmapConfig: TreeGraphHeatmapConfig;
  circular?: boolean;
  defaultTooltips?: TreeGraphDefaultTooltips;
  autoColorBranches: boolean;
  branchTransform: TreeGraphBranchTransform;
  type: GraphTypeEnum.TREE;
}

export type TreeGraphBranchTransform = 'noTransform' | 'cladogram' | 'equal';

export enum GraphTypeEnum {
  COLUMN = 'column',
  STACKED_COLUMN = 'stackedColumn',
  HEATMAP = 'heatmap',
  HEATMAP_TABLE = 'heatmapTable',
  TREE = 'tree',
}

export type GraphSidebarOptionsForGraph = Record<string, unknown>;

export type GraphSidebarOptions = Record<string, GraphSidebarOptionsForGraph>;
