<div [ngClass]="{ 'mt-5': navbarIsVisible$ | async }"></div>
<div class="d-inline float-end m-3">
  @for (toast of toasts$ | async; track toast) {
    @if (!isTemplate(toast)) {
      <ngb-toast
        [class]="(toast.className || '') + (' bg-' + toast.type)"
        [autohide]="toast.autohide !== false"
        [delay]="toast.delay || 5000"
        (hidden)="toastService.remove(toast)"
        data-testid="toast"
      >
        <ng-template ngbToastHeader>
          @if (toast.type === 'danger') {
            <i class="material-icons text-danger">report_problem</i>
          }
          <span class="ms-2 fw-bold me-auto">
            {{ toast.header }}
          </span>
        </ng-template>
        <span [ngClass]="{ 'text-light': toast.type === 'success' || toast.type === 'danger' }">
          {{ toast.content }}
        </span>
      </ngb-toast>
    } @else {
      <ng-template [ngTemplateOutlet]="toast.content"></ng-template>
    }
  }
</div>
