@if (showRestoreOverlay$ | async) {
  <bx-ngs-table-restoring-overlay
    [selectedTable]="(uiIndexStateAndTable$ | async).table"
    [uiIndexState]="(uiIndexStateAndTable$ | async).state"
    [isGraph]="true"
  ></bx-ngs-table-restoring-overlay>
} @else {
  @if (
    {
      data: data$ | async,
      freezeLayout: freezeLayout$ | async,
      nodeConfig: nodeConfig$ | async,
      colourNodes: colourNodes$ | async,
      showLegend: showLegend$ | async,
    };
    as params
  ) {
    @if (params.data && params.nodeConfig?.config) {
      <bx-graph-network
        [data]="params.data"
        [freezeLayout]="params.freezeLayout"
        [colourNodes]="params.colourNodes"
        [nodeConfig]="params.nodeConfig.config"
        [showLegend]="params.showLegend"
      >
      </bx-graph-network>
    }
  }
}
