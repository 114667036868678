import { ExportJobOptions } from 'src/nucleus/services/models/exportOptions.model';

/** These IDs are used for the UI only, such as setting Form States in User Settings.
 * They are NOT the true Pipeline IDs defined in the Nucleus Jobs table, instead refer to
 * {@link NucleusPipelineID}.
 */
export enum PipelineFormID {
  ALIGNMENT = 'alignment',
  ANNOTATION_ALIGNMENT = 'annotation-alignment',
  ANTIBODY_ANNOTATOR = 'ngs',
  BATCH_ASSEMBLE = 'batch-assemble',
  BATCH_RENAME = 'batch-rename',
  DOCUMENT_IMPORT = 'document-import',
  EXPORT_ALIGNMENT = 'export-alignment',
  EXPORT_DOCUMENT_FILE = 'export-document-file',
  EXPORT_DOCUMENT_METADATA = 'export-document-metadata',
  EXPORT_NGS_SEQUENCES = 'export-ngs-sequence',
  EXPORT_ALIGNMENT_SEQUENCES = 'export-alignment-sequence',
  EXPORT_NGS_TABLE = 'export-ngs-table',
  EXPORT_SEQUENCE_VIEWER_IMAGE = 'export-sequence-viewer-image',
  EXTRACT_REANALYZE = 'extract-reanalyze',
  EXTRACT_SEQUENCES = 'extract-sequences',
  FIND_HETEROZYGOTES = 'find-heterozygotes',
  GROUP_SEQUENCES = 'group-sequences',
  INDEX_DOCUMENT = 'index-document-table-rows',
  INDEX_SMALL_DOCUMENT = 'index-small-document-table-rows',
  MASTER_DATABASE_IMPORTER = 'master-database-importer',
  MASTER_DATABASE_SEARCH = 'master-database-search',
  MERGE_PAIRED_READS = 'paired-reads',
  MOTIF_ANNOTATOR = 'motif-annotator',
  NGS_COMPARISON = 'ngs-comparisons',
  NGS_RECLUSTER = 'ngs-recluster',
  PAIR_CHAINS = 'pair-chains',
  REGISTER_SEQUENCES = 'register-sequences',
  REGISTER_SEQUENCES_LUMA = 'register-sequences-luma',
  REMOVE_UMI_DUPLICATES = 'remove-umi-duplicates',
  REPAIR_SEQUENCES = 'repair-sequences',
  SINGLE_CELL_ANTIBODY_ANALYSIS = 'single-cell-antibody-analysis',
  TRIM_ENDS = 'trim-ends',
  ADD_CLUSTER = 'add-cluster',
  NGS_ANTIBODY_ANNOTATOR = 'ngs-antibody-annotator',
  RUN_FROM_JSON = 'run-from-json',
  EXPORT_FOR_EDITING = 'export-for-editing',
  PEPTIDE_ANNOTATOR = 'peptide-annotator',
  PROTEIN_ANNOTATOR = 'protein-annotator',
}

// Used to map the pipeline id in jobs to a nice display name.
const JOB_MAP: Record<NucleusPipelineID, string | ((options: any) => string)> = {
  alignment: 'Alignment',
  'annotation-alignment': 'Annotation Alignment',
  'batch-assemble': 'Batch Assemble',
  'batch-rename': 'Batch Rename',
  'document-import': 'Document Import',
  export: (options: ExportJobOptions) => {
    switch (options.exportFormat) {
      case 'documentFile':
        return 'Export Document';
      case 'documentMetadata':
        return 'Export Files Table';
      case 'ngsSequences':
        return 'Export Sequences';
      case 'ngsTable':
        return 'Export Table';
      default:
        return 'Export';
    }
  },
  'export-sequence-viewer-image': 'Export Sequence Viewer Image',
  'extract-sequences': 'Extract Sequences',
  'extract-reanalyze': 'Extract and Recluster Sequences',
  'generic-geneious': 'Find Heterozygotes',
  'group-sequences': 'Group Sequences',
  'index-document-table-rows': 'Index Document Table',
  'index-small-document-table-rows': 'Index Document Table (Small)',
  'master-database-importer': 'Collection Importer',
  'master-database-search': 'Collection Search',
  'motif-annotator': 'Motif Annotator',
  'organization-setup': (options: any) =>
    options.optionType === 'EXAMPLE_DATA_LOADING' ? 'Loading Example Data' : 'Organization Setup',
  ngs: 'Antibody Annotator',
  'ngs-comparisons': 'Comparison',
  'paired-reads': 'Set & Merge Paired Reads',
  recluster: 'Re-Cluster',
  'remove-umi-duplicates': 'Collapse UMI duplicates',
  'repair-sequences': 'Repair Sequences',
  report: 'Generate Report',
  /* TODO Keep this reference to scaffold-pipeline even though we don't have the pipeline anymore.
   *  Old job runs still exist from Scaffold Pipeline, even if it is unlikely the user would ever see it.
   *  We can remove most or all of these name mappings once we starting using the display name
   *  feature of the Jobs API.
   */
  'scaffold-pipeline': 'Scaffold Annotator',
  'single-cell-antibody-analysis': 'Single Cell Antibody Annotator',
  'trim-ends': 'Trim Ends',
  'import-and-annotate': 'Quick Analysis',
  'import-and-annotate-database': 'Format Reference Database',
  'register-sequences': 'Register Sequences in Dotmatics Bioregister',
  'register-sequences-luma': 'Register Sequences in Luma',
  'add-cluster': 'Add Clusters',
  'ngs-antibody-annotator': 'NGS Antibody Annotator',
  'run-from-json': 'Run Job From Parameters',
  'export-for-editing': 'Export for Editing',
  'peptide-annotator': 'Peptide Annotator',
  'protein-annotator': 'Protein Annotator',
};

/**
 * Returns a user friendly version of the job's name or defaults to the input job name
 */
export function getFriendlyJobName(machineName: NucleusPipelineID, options: any) {
  const mapValue = JOB_MAP[machineName];
  if (typeof mapValue === 'function') {
    return mapValue(options);
  } else if (mapValue) {
    return mapValue;
  } else {
    return machineName;
  }
}

// Refers to the true Pipeline ID. When a job is sent for example, it will target the pipeline via one of these ids.
export type ExactNucleusPipelineID =
  | 'alignment'
  | 'annotation-alignment'
  | 'batch-assemble'
  | 'batch-rename'
  | 'document-import'
  | 'export'
  | 'export-sequence-viewer-image'
  | 'extract-sequences'
  | 'extract-reanalyze'
  | 'generic-geneious'
  | 'group-sequences'
  | 'master-database-importer'
  | 'master-database-search'
  | 'motif-annotator'
  | 'ngs'
  | 'ngs-comparisons'
  | 'organization-setup'
  | 'paired-reads'
  | 'recluster'
  | 'remove-umi-duplicates'
  | 'repair-sequences'
  | 'report'
  /* TODO Keep this reference to scaffold-pipeline even though we don't have the pipeline anymore.
   *  Old job runs still exist from Scaffold Pipeline, even if it is unlikely the user would ever see it.
   *  We can remove most or all of these name mappings once we starting using the display name
   *  feature of the Jobs API.
   */
  | 'scaffold-pipeline'
  | 'single-cell-antibody-analysis'
  | 'trim-ends'
  | 'import-and-annotate'
  | 'import-and-annotate-database'
  | 'index-document-table-rows'
  | 'index-small-document-table-rows'
  | 'register-sequences'
  | 'register-sequences-luma'
  | 'add-cluster'
  | 'ngs-antibody-annotator'
  | 'run-from-json'
  | 'export-for-editing'
  | 'peptide-annotator'
  | 'protein-annotator';

export type NucleusPipelineID = ExactNucleusPipelineID | `${ExactNucleusPipelineID}:${string}`;

const PipelineIDMap: Record<PipelineFormID, NucleusPipelineID> = {
  [PipelineFormID.ALIGNMENT]: 'alignment',
  [PipelineFormID.ANNOTATION_ALIGNMENT]: 'annotation-alignment',
  [PipelineFormID.ANTIBODY_ANNOTATOR]: 'ngs',
  [PipelineFormID.BATCH_ASSEMBLE]: 'batch-assemble',
  [PipelineFormID.BATCH_RENAME]: 'batch-rename',
  [PipelineFormID.DOCUMENT_IMPORT]: 'document-import',
  [PipelineFormID.EXPORT_ALIGNMENT]: 'export',
  [PipelineFormID.EXPORT_DOCUMENT_FILE]: 'export',
  [PipelineFormID.EXPORT_DOCUMENT_METADATA]: 'export',
  [PipelineFormID.EXPORT_NGS_SEQUENCES]: 'export',
  [PipelineFormID.EXPORT_NGS_TABLE]: 'export',
  [PipelineFormID.EXPORT_ALIGNMENT_SEQUENCES]: 'export',
  [PipelineFormID.EXPORT_SEQUENCE_VIEWER_IMAGE]: 'export-sequence-viewer-image',
  [PipelineFormID.EXTRACT_REANALYZE]: 'extract-reanalyze',
  [PipelineFormID.EXTRACT_SEQUENCES]: 'extract-sequences',
  [PipelineFormID.FIND_HETEROZYGOTES]: 'generic-geneious',
  [PipelineFormID.GROUP_SEQUENCES]: 'group-sequences',
  [PipelineFormID.INDEX_DOCUMENT]: 'index-document-table-rows',
  [PipelineFormID.INDEX_SMALL_DOCUMENT]: 'index-small-document-table-rows',
  [PipelineFormID.MASTER_DATABASE_IMPORTER]: 'master-database-importer',
  [PipelineFormID.MASTER_DATABASE_SEARCH]: 'master-database-search',
  [PipelineFormID.MERGE_PAIRED_READS]: 'paired-reads',
  [PipelineFormID.MOTIF_ANNOTATOR]: 'motif-annotator',
  [PipelineFormID.NGS_COMPARISON]: 'ngs-comparisons',
  [PipelineFormID.NGS_RECLUSTER]: 'recluster',
  [PipelineFormID.PAIR_CHAINS]: 'paired-reads',
  [PipelineFormID.REGISTER_SEQUENCES]: 'register-sequences',
  [PipelineFormID.REGISTER_SEQUENCES_LUMA]: 'register-sequences-luma',
  [PipelineFormID.REMOVE_UMI_DUPLICATES]: 'remove-umi-duplicates',
  [PipelineFormID.REPAIR_SEQUENCES]: 'repair-sequences',
  [PipelineFormID.SINGLE_CELL_ANTIBODY_ANALYSIS]: 'single-cell-antibody-analysis',
  [PipelineFormID.TRIM_ENDS]: 'trim-ends',
  [PipelineFormID.ADD_CLUSTER]: 'add-cluster',
  [PipelineFormID.NGS_ANTIBODY_ANNOTATOR]: 'ngs-antibody-annotator',
  [PipelineFormID.RUN_FROM_JSON]: 'run-from-json',
  [PipelineFormID.EXPORT_FOR_EDITING]: 'export-for-editing',
  [PipelineFormID.PEPTIDE_ANNOTATOR]: 'peptide-annotator',
  [PipelineFormID.PROTEIN_ANNOTATOR]: 'protein-annotator',
};

export function getNucleusPipelineID(pipelineFormID: PipelineFormID): NucleusPipelineID {
  return PipelineIDMap[pipelineFormID];
}
