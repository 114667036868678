import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
  endWith,
  filter,
  finalize,
  first,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AppState } from '../core.store';
import {
  selectLongFileIDs,
  selectShortFileIDs,
} from '../files-table/files-table-store/files-table.selectors';

/**
 * A service that helps with getting and setting URL query parameters, e.g.
 * `selectRowID`.
 */
@Injectable({
  providedIn: 'root',
})
export class UrlQueryParamsService implements OnDestroy {
  private readonly _url$: BehaviorSubject<string>;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly store: Store<AppState>,
  ) {
    this._url$ = new BehaviorSubject(this.router.url);
    const unregister = this.location.onUrlChange((url) => this._url$.next(url));
    this._url$.pipe(finalize(() => unregister())).subscribe();
  }

  ngOnDestroy(): void {
    this._url$.complete();
  }

  get url$(): Observable<string> {
    return this._url$.asObservable();
  }

  /**
   * Parses the file IDs from the selectRowID query parameter. Returns an
   * observable that emits every time the url changes, and completes when the
   * URL no longer contains the folder ID in the route snapshot. The URL may
   * contain shortened UUIDs - these will be expanded to full UUIDs using the
   * files-table store.
   *
   * @param route a route with a :folderID in the path and and potentially a selectRowID query param
   * @returns an observable that emits file IDs
   * @throws if the route does not contain a :folderID param in the path
   */
  getFileIDsFromSelectRowID(route: ActivatedRoute): Observable<string[]> {
    const folderID = this.getFolderIDFromRoute(route);
    const folderChanged$ = this.url$.pipe(
      filter((url) => !url.includes(folderID)),
      endWith(() => ''),
      take(1),
    );
    return this.url$.pipe(
      map((url) => this.router.parseUrl(url)),
      map((urlTree) => this.parseSelectRowIDParam(urlTree)),
      switchMap((url) => this.store.select(selectLongFileIDs(folderID, url))),
      filter((ids) => ids != null),
      takeUntil(folderChanged$),
    );
  }

  /**
   * Sets the file IDs as the new selectRowID param value using location.replaceState
   * so that a navigation is not triggered. UUIDs are shortened using the files-table
   * store selectors. If there are more than 200 IDs, the selectRowID param will be
   * removed to avoid exceeding the max URL length of 2048 characters.
   *
   * @param route the current route with a :folderID in the path
   * @param fileIDs the full-length file UUIDs to set as the new selectRowID param value
   * @returns a single-emission observable that emits the new value of the selectRowID param
   *    (a string containing comma-separated shortened IDs, or null if the param was removed)
   */
  setSelectRowID(route: ActivatedRoute, fileIDs: string[]): Observable<string | null> {
    const folderID = this.getFolderIDFromRoute(route);
    let selectRowID$: Observable<string | null>;
    if (fileIDs.length > 0 && fileIDs.length <= 200) {
      selectRowID$ = this.store.select(selectShortFileIDs(folderID, fileIDs)).pipe(
        first((ids) => ids != null),
        map((shortIDs) => shortIDs.join(',')),
      );
    } else {
      selectRowID$ = of(null);
    }
    return selectRowID$.pipe(
      tap((selectRowID) => {
        const newURL = this.router
          .createUrlTree([], {
            relativeTo: route,
            queryParams: { selectRowID },
            queryParamsHandling: 'merge',
          })
          .toString();
        this.location.replaceState(newURL);
      }),
    );
  }

  setSelectedTable(route: ActivatedRoute, selectedTable: string) {
    const newURL = this.router
      .createUrlTree([], {
        relativeTo: route,
        queryParams: { selectedTable },
        queryParamsHandling: 'merge',
      })
      .toString();
    this.location.replaceState(newURL);
  }

  private getFolderIDFromRoute(route: ActivatedRoute): string {
    const folderID = route.snapshot.params.folderID;
    if (!folderID) {
      throw new Error('Url does not contain a :folderID param: ' + route.toString());
    }
    return folderID;
  }

  /**
   * Extracts the selectRowID query parameter and converts it to an array of IDs.
   * If the query parameter isn't present, returns an empty array.
   *
   * @param url the parsed URL
   * @returns the row IDs
   */
  private parseSelectRowIDParam(url: { queryParams: Params }): string[] {
    const selectRowID: string | undefined = url.queryParams.selectRowID;
    if (!selectRowID) {
      return [];
    }
    return Array.from(new Set(selectRowID.split(',')));
  }
}
