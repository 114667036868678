import { Injectable } from '@angular/core';
import { JobStatus } from '@geneious/nucleus-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { NEVER } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { JobActivityEventKind } from '../../../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import { ActivityKind } from '../../../../../nucleus/v2/models/activity-events/activity-kind.model';
import {
  JobEventType,
  NonStageJobEvent,
  getJobID,
} from '../../../../../nucleus/v2/models/activity-events/job-activity-event.model';
import { ActivityStreamService } from '../../../activity/activity-stream.service';
import { selectIsAuthenticated } from '../../../auth/auth.selectors';
import { AppState } from '../../../core.store';
import { JobsService } from '../../job.service';
import { setJobStatus } from './job-websocket.actions';
import {
  clearNotInProgressJobs,
  fetchRunningJobsStatuses,
  fetchRunningJobsStatusesSuccess,
} from './jobs-status.actions';

@Injectable()
export class JobsStatusEffects {
  readonly jobEvents$ = this.store.select(selectIsAuthenticated).pipe(
    switchMap((auth) => (auth ? this.activityStreamService.listenToJobActivity() : NEVER)),
    filter((activity) => activity.kind === ActivityKind.JOB),
    map((activity) => activity.event),
    filter((event) => !this.isImportJob(event)),
  );
  readonly syncJobStatus$ = createEffect(() =>
    this.jobEvents$.pipe(
      filter((event): event is NonStageJobEvent =>
        [
          JobActivityEventKind.JOB_QUEUED,
          JobActivityEventKind.JOB_STARTED,
          JobActivityEventKind.JOB_COMPLETED,
          JobActivityEventKind.JOB_FAILED,
          JobActivityEventKind.JOB_CANCELLED,
        ].includes(event.kind),
      ),
      map((event) => setJobStatus({ id: event.jobID, status: event.kind })),
      // Ignore other job websocket events
      filter((action) => !!action),
    ),
  );

  fetchJobs$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchRunningJobsStatuses),
      switchMap(() =>
        this.jobsService.getUserJobs().pipe(
          map((response) => response.data),
          map((jobs) =>
            jobs.filter((job) => {
              return (
                (job.config.pipeline.name !== 'document-import' && job.status.kind === 'Queued') ||
                job.status.kind === 'Running'
              );
            }),
          ),
          map((jobs) =>
            jobs.map((job) => ({
              id: job.id,
              status: this.jobStatusToEventStatus(job.status.kind),
            })),
          ),
          map((jobs) => fetchRunningJobsStatusesSuccess({ jobs })),
        ),
      ),
    ),
  );

  /** Matches "/jobs" and "/jobs?bing=bong", but not "/jobs/uuid" or "/jobsearch" */
  private readonly jobsPageRegex = /^\/jobs(\?|$)/;
  readonly clearJobStatusesOnJobsPageNavigation$ = createEffect(() =>
    this.actions.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) => this.jobsPageRegex.test(payload.event.url)),
      map(() => clearNotInProgressJobs()),
    ),
  );

  private imports = new Map<string, boolean>();

  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private jobsService: JobsService,
    private activityStreamService: ActivityStreamService,
  ) {}

  private isImportJob(event: JobEventType): boolean {
    const id = getJobID(event);
    if (
      event.kind === JobActivityEventKind.JOB_QUEUED &&
      event.jobConfig.pipeline.name === 'document-import'
    ) {
      this.imports.set(id, true);
    } else if (event.kind === JobActivityEventKind.JOB_COMPLETED && this.imports.has(id)) {
      return this.imports.delete(id);
    }
    return this.imports.has(id);
  }

  private jobStatusToEventStatus(kind: JobStatus['kind']) {
    switch (kind) {
      case 'Cancelled':
        return JobActivityEventKind.JOB_CANCELLED;
      case 'Completed':
        return JobActivityEventKind.JOB_COMPLETED;
      case 'Failing':
        return JobActivityEventKind.JOB_FAILED;
      case 'Running':
      case 'Queued':
        return JobActivityEventKind.JOB_STARTED;
    }
  }
}
