import { ViewerMultipleTableDocumentSelection } from '../../../viewer-components/viewer-document-data';
import { DocumentTable } from '../../../../../nucleus/services/documentService/types';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import {
  ngsGraphActions,
  ParamWithoutValidation,
  possibleParams,
} from './ngs-graph-data-store.actions';
import {
  ExtraSelectionDataFor,
  GraphDataFor,
  GraphId,
  GraphTypes,
  SelectionDisplayType,
} from '../ngs-graphs.model';
import {
  allParamsValid,
  optionsAreEqual,
  selectionsAreEqual,
} from './ngs-graph-data-store.effects';
import { getRowIdentifier, getRowIdentifierColumnName } from '../../getRowIdentifier';
import { isClusterTable } from '../../table-type-filters';

export const initialState: NgsGraphState = {};
const initialTableState = {};

export function initSelection<T extends GraphId>(
  partialSelection: Partial<GraphSelectionDataFor<T>>,
): GraphSelectionDataFor<T> {
  return {
    documentName: withValidity(null, false),
    selectedGraph: withValidity(null, false),
    selectedTable: withValidity(null, false),
    tableSelection: withValidity(getDefaultTableSelection(), true),
    selectionDisplayType: withValidity('auto', true),
    filter: withValidity(null, false),
    options: withValidity(null, false),
    updatedAt: withValidity(Date.now(), true),
    ...partialSelection,
  };
}

export function graphSelectionUnchanged<T extends GraphId>(
  previousSelection: GraphSelectionDataFor<T>,
  currentSelection: GraphSelectionDataFor<T>,
): boolean {
  const graphId = currentSelection.selectedGraph.value as GraphId;
  return (
    // if the table changes, the cached data is no longer valid
    currentSelection?.selectedTable?.value?.name ===
      previousSelection?.selectedTable?.value?.name &&
    // if the table selection changes AND the selection matters for this graph, the cached data is no longer valid
    (isIgnorableParameterForGraph(graphId, 'tableSelection') ||
      selectionsAreEqual(
        currentSelection?.tableSelection?.value,
        previousSelection?.tableSelection?.value,
      )) &&
    // if the selection display type (all rows, selected rows only, etc) AND it matters for this graph, the cached data is no longer valid
    (isIgnorableParameterForGraph(graphId, 'selectionDisplayType') ||
      currentSelection?.selectionDisplayType?.value ===
        previousSelection?.selectionDisplayType?.value) &&
    // if the selected graph doesn't match the cached data, the data is invalid
    // (note: even if the selected graph has changed, we should _still_ expect these to match)
    currentSelection?.selectedGraph?.value === previousSelection?.selectedGraph?.value &&
    // if the filter changes and the graph cares about the filter, the table selection changes, so the cached data is invalid
    (isIgnorableParameterForGraph(graphId, 'filter') ||
      currentSelection?.filter?.value === previousSelection?.filter?.value) &&
    // if the graph has extra selection options (e.g. 'length' for codon usage), and these options change, the graph data should be refreshed
    (isIgnorableParameterForGraph(graphId, 'options') ||
      optionsAreEqual<typeof graphId>(
        currentSelection?.options?.value,
        previousSelection?.options?.value,
      ))
  );
}

export function isIgnorableParameterForGraph(
  graphId: GraphId,
  param: keyof GraphSelectionDataFor<typeof graphId>,
) {
  return (
    (param === 'options' &&
      !['aminoAcidDistribution', 'codonDistribution', 'geneCombinations', 'sankey'].includes(
        graphId,
      )) ||
    (!['clusterDiversity', 'clusterLengths', 'clusterSizes'].includes(graphId) &&
      (param === 'tableSelection' || param === 'selectionDisplayType')) ||
    (['clusterSummaryTree', 'clusterSummaryNetwork'].includes(graphId) && param === 'filter')
  );
}

export const initialDocumentState = <T extends GraphId>(
  selection: Partial<GraphSelectionDataFor<T>>,
): NgsGraphDocumentState => {
  return {
    loading: false,
    currentSelection: initSelection(selection) as GraphSelectionData,
    graphData: {
      DOCUMENT_TABLE_ALL_SEQUENCES: {
        ...initialTableState,
        [GraphTypes.None.id]: {
          data: null,
          selection: initSelection(selection) as GraphSelectionDataFor<typeof GraphTypes.None.id>,
          updatedAt: Date.now(),
        },
      },
    },
  };
};

/**
 * If a param is "invalid", we will not fetch graph data with it.
 * We invalidate parameters when they depend on other parameters that have just changed,
 * but they can't immediately be updated.
 */
interface WithValidationStatus<T> {
  value: T;
  valid: boolean;
}

export type SelectionForGraph = {
  selectAll: boolean;
  selectedRows: string[];
  rows: string[];
  total: number;
  documentName?: string;
  documentId?: string;
  rowIdentifierColumnName: string;
};

export type TableForGraph = Pick<
  DocumentTable,
  'name' | 'displayName' | 'tableType' | 'metadata' | 'columns'
>;

export function getTableSelection(
  { document, total, ...selection }: ViewerMultipleTableDocumentSelection,
  table: DocumentTable,
): SelectionForGraph {
  const clusterTable = isClusterTable(table) ? table.name : null;
  return {
    selectAll: selection.selectAll,
    selectedRows: selection.selectedRows.map((x) => getRowIdentifier(x, clusterTable)),
    rows: selection.rows.map((x) => getRowIdentifier(x, clusterTable)),
    rowIdentifierColumnName:
      selection.rows.map((x) => getRowIdentifierColumnName(x, clusterTable)).find((x) => x) ??
      'geneious_row_index',
    total,
  };
}

export function getTableForGraph({
  name,
  displayName,
  tableType,
  metadata,
  columns,
}: DocumentTable): TableForGraph {
  return { name, displayName, tableType, metadata, columns };
}

export function getDefaultTableSelection(): SelectionForGraph {
  return {
    selectAll: false,
    rows: [],
    selectedRows: [],
    rowIdentifierColumnName: 'geneious_row_index',
    total: 0,
  };
}

export interface GraphSelectionDataFor<T extends GraphId> {
  documentName: WithValidationStatus<string>;
  selectedGraph: WithValidationStatus<T>;
  selectedTable: WithValidationStatus<TableForGraph>;
  tableSelection: WithValidationStatus<SelectionForGraph>;
  selectionDisplayType: WithValidationStatus<SelectionDisplayType['id']>;
  filter: WithValidationStatus<string>;
  options: WithValidationStatus<ExtraSelectionDataFor<T>>;
  updatedAt: WithValidationStatus<number>;
}

export type GraphSelectionData = {
  [K in GraphId]: GraphSelectionDataFor<K>;
}[GraphId];

export type GraphDataAndSelectionForId<T extends GraphId> = {
  selection: GraphSelectionDataFor<T>;
  data: GraphDataFor<T>;
  updatedAt: number;
};

export interface NgsGraphDocumentState {
  loading: boolean;
  currentSelection: GraphSelectionDataFor<any>;
  graphData: {
    [tableName: string]: Partial<{
      [graphId in GraphId]: GraphDataAndSelectionForId<graphId>;
    }>;
  };
}

export interface NgsGraphState {
  [documentId: string]: NgsGraphDocumentState;
}

export function withValidityDependingOnParamAndValue<
  T extends GraphId,
  U extends keyof GraphSelectionDataFor<T>,
  V,
>(param: U, value: V, valid: boolean): WithValidationStatus<V> {
  if (param === 'selectedGraph' && value === 'noGraph') {
    return withValidity(value, false);
  }
  return withValidity(value, valid);
}
export function withValidity<T>(value: T, valid: boolean): WithValidationStatus<T> {
  return {
    value,
    valid,
  };
}

const dependencies: { [key in keyof GraphSelectionData]: Array<keyof GraphSelectionData> } = {
  selectedTable: ['options'],
  selectedGraph: ['options'],
  tableSelection: [],
  selectionDisplayType: [],
  filter: [],
  documentName: [],
  options: [],
  updatedAt: [],
};

const getDependentParamsWithValidityChanged = (
  param: keyof GraphSelectionData,
  graphId: GraphId,
  from: GraphSelectionDataFor<typeof graphId>,
) => {
  return dependencies[param].reduce(
    (acc, val) => ({
      ...acc,
      [val]: withValidityDependingOnParamAndValue(val, from[val].value, false),
    }),
    {} as Partial<GraphSelectionDataFor<typeof graphId>>,
  );
};
const paramUpdateActions = possibleParams.map((param) => ngsGraphActions.params[param].update);
const paramOn = on(...paramUpdateActions, (state: NgsGraphState, { id, graphId, param, value }) => {
  const currentParams = state[id]?.currentSelection ?? initSelection({});
  const dependentParams = getDependentParamsWithValidityChanged(param, graphId, currentParams);
  if (!state[id]) {
    return {
      ...state,
      [id]: initialDocumentState<typeof graphId>({
        ...dependentParams,
        [param]: withValidityDependingOnParamAndValue(
          param,
          (value as ParamWithoutValidation<typeof graphId, typeof param>)[param],
          true,
        ),
      }),
    };
  }
  const oldSelection = state[id]?.currentSelection;
  const currentSelection = {
    ...oldSelection,
    ...dependentParams,
    [param]: withValidityDependingOnParamAndValue(
      param,
      (value as ParamWithoutValidation<typeof graphId, typeof param>)[param],
      true,
    ),
    updatedAt: withValidity(Date.now(), true),
  };
  return {
    ...state,
    [id]: {
      ...state[id],
      currentSelection,
    },
  };
});

export const ngsGraphDataReducer: ActionReducer<NgsGraphState> = createReducer<NgsGraphState>(
  initialState,
  paramOn,
  on(ngsGraphActions.setLoading, (state, { id, loading }) => {
    return {
      ...state,
      [id]: {
        ...state[id],
        loading,
      },
    };
  }),
  on(ngsGraphActions.data.fetchGraphData, (state, { id }) => {
    return {
      ...state,
      [id]: {
        ...state[id],
        loading: true,
      },
    };
  }),
  on(ngsGraphActions.data.fetchGraphDataSuccess, (state, { id, graphId, tableName, data }) => {
    const currentAllGraphData = state[id]?.graphData;
    const currentTableGraphData = currentAllGraphData[tableName];
    return {
      ...state,
      [id]: {
        ...state[id],
        loading: false,
        graphData: {
          ...currentAllGraphData,
          [tableName]: {
            ...currentTableGraphData,
            [graphId]: {
              selection: state[id]?.currentSelection,
              data,
              updatedAt: Date.now(),
            },
          },
        },
      },
    };
  }),
  on(ngsGraphActions.data.fetchGraphDataFail, (state, { id, graphId, tableName }) => {
    const currentGraphData = state[id]?.graphData;
    const currentSelectedTableData = currentGraphData?.[tableName] ?? {};

    return {
      ...state,
      [id]: {
        ...state[id],
        loading: false,
        graphData: {
          ...currentGraphData,
          [tableName]: {
            ...currentSelectedTableData,
            [graphId]: {
              data: null,
              selection: state[id]?.currentSelection,
              updatedAt: Date.now(),
            },
          },
        },
      },
    };
  }),
);
