import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { StackedColumnColorSchemeHandler } from '../../../../../features/graphs/datasources/stacked-column-colorscheme-handler';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core.store';
import {
  GraphControlTypeEnum,
  GraphSidebarControl,
} from '../../../../../features/graphs/graph-sidebar';
import { selectDataForNgsDocument } from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import { GraphDataFor } from '../../ngs-graphs.model';
import { SeriesColumnOptions } from 'highcharts';
import { StackedColumnChartComponent } from '../../../../../features/graphs/stacked-column-chart/stacked-column-chart.component';
import {
  geneFamilyNameMap,
  getGeneFamilyDataFromReport,
} from '../../../ngs-report-viewer/ngs-report.service';
import { sortAntibodyRegionByName } from '../../../../../shared/sort.util';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../../../shared/page-message/page-message.component';
import { GraphSidebarOptionsService } from '../../../../user-settings/graph-sidebar-options/graph-sidebar-options.service';
import { DocumentTableStateService } from '../../../../document-table-service/document-table-state/document-table-state.service';

@Component({
  selector: 'bx-ngs-gene-family-usage-graph',
  templateUrl: './ngs-gene-family-usage-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PageMessageComponent, StackedColumnChartComponent, AsyncPipe],
})
export class NgsGeneFamilyUsageGraphComponent
  extends NgsBaseGraphComponent<NGSGeneFamilyData, StackedColumnChartComponent>
  implements OnInit, OnDestroy
{
  @ViewChild(StackedColumnChartComponent) chartComponent: StackedColumnChartComponent;

  regions$: Observable<string[]>;
  regionControl$ = new BehaviorSubject<string>(null);
  region$: Observable<string>;
  showLegendControl$ = new BehaviorSubject(false);
  showLegend$: Observable<boolean>;
  data$: Observable<NGSGeneFamilyData>;
  private colorSchemeHandler: StackedColumnColorSchemeHandler;

  constructor(
    protected store: Store<AppState>,
    protected graphSidebarOptionsService: GraphSidebarOptionsService,
    protected documentTableStateService: DocumentTableStateService,
  ) {
    super(store, graphSidebarOptionsService, documentTableStateService);
    this.colorSchemeHandler = new StackedColumnColorSchemeHandler('AminoAcid');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.regions$ = this.getRegions();
    this.applySavedOptions();
    this.regions$
      .pipe(
        filter((regions) => !!regions && regions.length > 0),
        take(1),
      )
      .subscribe((regions) => {
        if (!this.regionControl$.value) {
          this.regionControl$.next(regions[0]);
        }
      });
    this.region$ = this.regionControl$.pipe(
      filter((x) => !!x),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),
    );
    this.data$ = combineLatest([
      this.store.pipe(
        selectDataForNgsDocument<'geneFamilyUsage'>(this.documentID, 'geneFamilyUsage'),
        takeUntil(this.ngUnsubscribe),
      ),
      this.region$,
    ]).pipe(
      filter(([data, region]) => !!data && !!region),
      map(([data, region]) => this.process(data, region)),
    );
    this.showLegend$ = this.showLegendControl$.pipe(
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),
    );

    combineLatest([this.regions$, this.showLegend$])
      .pipe(
        withLatestFrom(this.region$),
        map(
          ([[regions, showLegend], region]) =>
            [
              {
                name: 'region',
                label: 'Gene',
                type: GraphControlTypeEnum.SELECT,
                defaultOption: region,
                options: regions.map((region) => {
                  return {
                    displayName: region,
                    value: region,
                  };
                }),
              },
              this.colorSchemeHandler.getLegendControl(showLegend),
            ] as GraphSidebarControl[],
        ),
      )
      .subscribe(this.controls$);
  }

  onControlsChanged({ region, showLegend }: any) {
    this.showLegendControl$.next(showLegend);
    this.regionControl$.next(region);
  }

  private process(reportData: GraphDataFor<'geneFamilyUsage'>, region: string): NGSGeneFamilyData {
    const chartData = getGeneFamilyDataFromReport(reportData, region);
    return {
      title: `${region ?? 'Gene'} Family Usage`,
      data: chartData,
    };
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.regionControl$.complete();
    this.showLegendControl$.complete();
  }

  private getRegions() {
    return this.store.pipe(
      selectDataForNgsDocument<'geneFamilyUsage'>(this.documentID, 'geneFamilyUsage'),
      takeUntil(this.ngUnsubscribe),
      filter((data) => !!data),
      map((data) =>
        Object.keys(data.statistics.geneFamilyUsage)
          .map(geneFamilyNameMap)
          .filter((x) => !!x)
          .filter((header) => header !== 'Region' && header !== 'As % Of')
          .sort(sortAntibodyRegionByName),
      ),
      tap((data) => {
        if (data.length === 0) {
          this.error$.next(
            'There is no Gene Family Usage data for this result. To view this graph you will need to re-run your analysis.',
          );
        }
      }),
      catchError((e) => {
        this.error$.next(
          'There is no Gene Family Usage data for this result. To view this graph you will need to re-run your analysis.',
        );
        return of([]);
      }),
    );
  }
}

interface NGSGeneFamilyData {
  data: SeriesColumnOptions[];
  title: string;
}
