import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ColumnChartComponent } from '../../../../../features/graphs/column-chart/column-chart.component';
import { combineLatest, Observable } from 'rxjs';
import { GraphOption } from '../../../../../features/graphs/graph-aba-data.service';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import {
  selectDataForNgsDocument,
  selectGraphParamsForNgsDocument,
} from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import { sanitizeDTSTableOrColumnName } from '../../../../../../nucleus/services/documentService/document-service.v1';
import {
  getTotalColumn,
  GraphDocumentDataService,
} from '../../../../../features/graphs/graph-document-data.service';
import { IBarChartInfo } from '../../../../../features/graphs/column-chart/BarChartInfo.model';
import { ExtraSelectionDataFor, GraphDataFor } from '../../ngs-graphs.model';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import {
  DocumentTable,
  TableMetadata,
} from '../../../../../../nucleus/services/documentService/types';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../../../shared/page-message/page-message.component';
import { SuffixPipe } from '../../suffix.pipe';
import { DocumentTableUIIndexState } from '../../../../document-table-service/document-table-state/document-table-state';
import { NgsTableRestoringOverlayComponent } from '../../../ngs-table-restoring-overlay/ngs-table-restoring-overlay.component';

@Component({
  selector: 'bx-ngs-cluster-diversity-graph',
  templateUrl: './ngs-cluster-diversity-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PageMessageComponent,
    ColumnChartComponent,
    AsyncPipe,
    SuffixPipe,
    NgsTableRestoringOverlayComponent,
  ],
})
export class NgsClusterDiversityGraphComponent
  extends NgsBaseGraphComponent<IBarChartInfo, ColumnChartComponent>
  implements OnInit
{
  @ViewChild(ColumnChartComponent) chartComponent: ColumnChartComponent;

  regions$: Observable<GraphOption[]>;
  uiIndexStateAndTable$: Observable<{ state: DocumentTableUIIndexState; table: DocumentTable }>;
  showRestoreOverlay$: Observable<boolean>;

  ngOnInit() {
    super.ngOnInit();
    const selectedTable$ = this.selectedTable$.pipe(map((table) => table.name));
    const tableIndexState$ = this.getSelectedTableIndexState(selectedTable$);
    this.uiIndexStateAndTable$ = this.getUiIndexStateAndTable(tableIndexState$);
    this.showRestoreOverlay$ = this.getShowRestoreOverlay(tableIndexState$).pipe(
      tap((showOverlay) => this.hideLoadingSpinner(showOverlay)),
    );
    const tableDataQueryable$ = this.emitWhenTableIsQueryable(tableIndexState$);
    this.data$ = combineLatest([
      this.store.pipe(
        selectDataForNgsDocument<'clusterDiversity'>(this.documentID, 'clusterDiversity'),
        takeUntil(this.ngUnsubscribe),
      ),
      this.store
        .select(selectGraphParamsForNgsDocument(this.documentID))
        .pipe(takeUntil(this.ngUnsubscribe)),
      tableDataQueryable$,
    ]).pipe(
      filter(([data, _]) => !!data),
      map(([data, { currentSelection }, _]) =>
        this.process(data, {
          name: currentSelection.selectedTable.value.displayName,
          metadata: currentSelection.selectedTable.value.metadata,
          columns: currentSelection.selectedTable.value.columns.map((col) => col.displayName),
        }),
      ),
    );
  }

  exportAsImage() {
    this.selectedParams$
      .pipe(
        take(1),
        map((data) => data?.currentSelection?.documentName?.value),
        filter((x) => !!x),
      )
      .subscribe((documentName) => this.chartComponent.downloadImage(documentName));
  }

  exportAsTable() {
    this.selectedParams$
      .pipe(
        take(1),
        map((data) => data?.currentSelection?.documentName?.value),
        filter((x) => !!x),
      )
      .subscribe((documentName) =>
        this.chartComponent.downloadTable({
          documentName,
        }),
      );
  }

  private process(
    data: GraphDataFor<'clusterDiversity'>,
    region: { name: string; metadata: TableMetadata; columns: string[] },
  ): IBarChartInfo {
    const xKey = getTotalColumn(
      region.columns,
      region.metadata?.clusters?.usedBeforeCollapsingFrequencies,
    );
    const yKey = 'yValue';
    const xLabel = 'Cluster size';
    const yLabel = 'Number of Clusters';
    const name = `${region.name} Cluster Diversity`;
    return GraphDocumentDataService.formatAggregateBarChartData(
      { data },
      name,
      xLabel,
      yLabel,
      xKey,
      yKey,
    );
  }

  static aggregateQuery(
    region: string,
    metadata: TableMetadata,
    columns: string[],
  ): ExtraSelectionDataFor<'clusterDiversity'> {
    const xKey = getTotalColumn(columns, metadata?.clusters?.usedBeforeCollapsingFrequencies);
    const yKey = 'yValue';
    return {
      fields: [`${xKey}`, `COUNT('${sanitizeDTSTableOrColumnName(region)} ID') ${yKey}`],
      orderBy: [{ kind: 'ascending', field: xKey }],
      groupBy: [xKey],
    };
  }
}
