import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ColumnChartComponent } from '../../../../../features/graphs/column-chart/column-chart.component';
import { Observable, switchMap } from 'rxjs';
import { GraphOption } from '../../../../../features/graphs/graph-aba-data.service';
import { filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import {
  selectDataForNgsDocument,
  selectGraphParamsForNgsDocument,
} from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import { ResultDocumentFieldEnum as FIELDS } from '../../../../../features/graphs/result-document-field.enum';
import {
  getTotalColumn,
  GraphDocumentDataService,
} from '../../../../../features/graphs/graph-document-data.service';
import { IBarChartInfo } from '../../../../../features/graphs/column-chart/BarChartInfo.model';
import { ExtraSelectionDataFor, GraphDataFor } from '../../ngs-graphs.model';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import {
  DocumentTable,
  TableMetadata,
} from '../../../../../../nucleus/services/documentService/types';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../../../shared/page-message/page-message.component';
import { SuffixPipe } from '../../suffix.pipe';
import { DocumentTableUIIndexState } from '../../../../document-table-service/document-table-state/document-table-state';
import { NgsTableRestoringOverlayComponent } from '../../../ngs-table-restoring-overlay/ngs-table-restoring-overlay.component';

@Component({
  selector: 'bx-ngs-cluster-lengths-graph',
  templateUrl: './ngs-cluster-lengths-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PageMessageComponent,
    ColumnChartComponent,
    AsyncPipe,
    SuffixPipe,
    NgsTableRestoringOverlayComponent,
  ],
})
export class NgsClusterLengthsGraphComponent
  extends NgsBaseGraphComponent<IBarChartInfo, ColumnChartComponent>
  implements OnInit
{
  @ViewChild(ColumnChartComponent) chartComponent: ColumnChartComponent;
  regions$: Observable<GraphOption[]>;
  uiIndexStateAndTable$: Observable<{ state: DocumentTableUIIndexState; table: DocumentTable }>;
  showRestoreOverlay$: Observable<boolean>;

  ngOnInit() {
    super.ngOnInit();
    const selectedTable$ = this.selectedTable$.pipe(map((table) => table.name));
    const tableIndexState$ = this.getSelectedTableIndexState(selectedTable$);
    this.uiIndexStateAndTable$ = this.getUiIndexStateAndTable(tableIndexState$);
    this.showRestoreOverlay$ = this.getShowRestoreOverlay(tableIndexState$).pipe(
      tap((showOverlay) => this.hideLoadingSpinner(showOverlay)),
    );
    const tableDataQueryable$ = this.emitWhenTableIsQueryable(tableIndexState$);
    this.data$ = tableDataQueryable$.pipe(
      switchMap(() =>
        this.store.pipe(
          selectDataForNgsDocument<'clusterLengths'>(this.documentID, 'clusterLengths'),
          takeUntil(this.ngUnsubscribe),
          filter((data) => !!data),
          withLatestFrom(this.store.select(selectGraphParamsForNgsDocument(this.documentID))),
          map(([data, { currentSelection }]) =>
            this.process(data, { name: currentSelection.selectedTable.value.displayName }),
          ),
        ),
      ),
    );
  }

  private process(data: GraphDataFor<'clusterLengths'>, region: { name: string }): IBarChartInfo {
    const xKey = FIELDS.LENGTH;
    const yKey = 'yValue';
    const xLabel = `${region.name} length`;
    const yLabel = 'Number of Sequences';
    const name = `${region.name} Cluster Lengths`;
    return GraphDocumentDataService.formatAggregateBarChartData(
      { data },
      name,
      xLabel,
      yLabel,
      xKey,
      yKey,
    );
  }

  static aggregateQuery(
    metadata: TableMetadata,
    columns: string[],
  ): ExtraSelectionDataFor<'clusterLengths'> {
    const xKey = FIELDS.LENGTH;
    const yKey = 'yValue';
    const total = getTotalColumn(columns, metadata?.clusters?.usedBeforeCollapsingFrequencies);
    return {
      fields: [xKey, `SUM(${total}) ${yKey}`],
      orderBy: [{ kind: 'ascending', field: FIELDS.LENGTH }],
      groupBy: [xKey],
    };
  }
}
