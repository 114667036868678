import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { CODON_USAGE_TABLE, GraphDataFor } from '../../ngs-graphs.model';
import {
  GraphControlTypeEnum,
  GraphSidebarControl,
} from '../../../../../features/graphs/graph-sidebar';
import { AppState } from '../../../../core.store';
import { Store } from '@ngrx/store';
import { selectDataForNgsDocument } from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { GraphDocumentDataService } from '../../../../../features/graphs/graph-document-data.service';
import { GraphHeatmapComponent } from '../../../../../features/graphs/graph-heatmap/graph-heatmap.component';
import { ngsGraphActions } from '../../ngs-graph-data-store/ngs-graph-data-store.actions';
import { HeatmapInfo } from '../../../../../features/graphs/graph-heatmap/heatmap-info.model';
import { DownloadBlobService } from '../../../../download-blob.service';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import { AsyncPipe } from '@angular/common';
import { ClientGridComponent } from '../../../../../features/grid/client-grid/client-grid.component';
import { GraphSidebarOptionsService } from '../../../../user-settings/graph-sidebar-options/graph-sidebar-options.service';
import { NgsTableRestoringOverlayComponent } from '../../../ngs-table-restoring-overlay/ngs-table-restoring-overlay.component';
import {
  DocumentTableUIIndexState,
  isQueryableIndexState,
} from '../../../../document-table-service/document-table-state/document-table-state';
import { DocumentTable } from '../../../../../../nucleus/services/documentService/types';
import { DocumentTableStateService } from '../../../../document-table-service/document-table-state/document-table-state.service';

@Component({
  selector: 'bx-ngs-codon-distribution-chart',
  templateUrl: './ngs-codon-distribution-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ClientGridComponent,
    GraphHeatmapComponent,
    AsyncPipe,
    NgsTableRestoringOverlayComponent,
  ],
})
export class NgsCodonDistributionChartComponent
  extends NgsBaseGraphComponent<HeatmapInfo, GraphHeatmapComponent>
  implements OnInit, OnDestroy
{
  @ViewChild(GraphHeatmapComponent) chartComponent: GraphHeatmapComponent;

  rawData$: Observable<GraphDataFor<'codonDistribution'>>;
  lengthControl$ = new ReplaySubject<number | string>(1);
  length$: Observable<number | string>;
  lengths$ = new ReplaySubject<number[]>(1);
  isTableControl$ = new BehaviorSubject(false);
  isTable$: Observable<boolean>;
  isWrapped$: Observable<boolean>;
  isWrappedControl$ = new BehaviorSubject(false);
  uiIndexStateAndTable$: Observable<{ state: DocumentTableUIIndexState; table: DocumentTable }>;
  showRestoreOverlay$: Observable<boolean>;

  constructor(
    protected store: Store<AppState>,
    private readonly graphDocumentDataService: GraphDocumentDataService,
    protected graphSidebarOptionsService: GraphSidebarOptionsService,
    protected documentTableStateService: DocumentTableStateService,
  ) {
    super(store, graphSidebarOptionsService, documentTableStateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.lengthControl$.next('All lengths');
    const selectedParamChanged$ = this.selectedParams$.pipe(
      map((params) => params.currentSelection.selectedTable.value),
      distinctUntilChanged((prev, current) => prev.name === current.name),
      takeUntil(this.ngUnsubscribe),
    );
    const codonTableIndexState$ = selectedParamChanged$.pipe(
      switchMap(() =>
        this.documentTableStateService.getUIIndexState(this.documentID, CODON_USAGE_TABLE),
      ),
      takeUntil(this.ngUnsubscribe),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.uiIndexStateAndTable$ = codonTableIndexState$.pipe(
      filter((indexState) => !isQueryableIndexState(indexState.currentIndexState)),
      withLatestFrom(this.documentTableStateService.getTable(this.documentID, CODON_USAGE_TABLE)),
      map(([state, table]) => ({ state, table })),
      tap(() => {
        //set loading to false so that restoring overlay is visible
        this.store.dispatch(ngsGraphActions.setLoading({ id: this.documentID, loading: false }));
      }),
    );
    this.showRestoreOverlay$ = codonTableIndexState$.pipe(
      map((indexState) => !isQueryableIndexState(indexState.currentIndexState)),
    );
    const selectedTable$ = combineLatest([
      selectedParamChanged$,
      codonTableIndexState$.pipe(
        filter((indexState) => isQueryableIndexState(indexState.currentIndexState)),
      ),
    ]).pipe(map(([tableForGraph, _]) => tableForGraph));
    this.length$ = combineLatest([
      this.lengthControl$.pipe(distinctUntilChanged()),
      selectedTable$,
    ]).pipe(map(([length, _]) => length));
    this.isTable$ = this.isTableControl$.pipe(distinctUntilChanged());
    this.isTable$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isTable) => {
      this.pngExportEnabled$.next(!isTable);
    });
    selectedTable$
      .pipe(
        switchMap((region) =>
          this.graphDocumentDataService.getClusterLengths(this.documentID, {
            name: region.displayName,
            table: region.name,
            tableType: region.tableType,
            columns: region.columns,
            metadata: region.metadata,
          }),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((len) => {
        if (!len || len.length === 0) {
          this.error$.next('No valid length options could be found');
        }
        this.lengths$.next(len);
      });
    this.lengths$
      .pipe(
        filter((lengths) => !!lengths),
        distinctUntilChanged(),
      )
      .subscribe((lengths) => {
        const hasSavedLength = !!this.savedControls$?.value?.length;
        if (!hasSavedLength || !lengths.includes(this.savedControls$?.value?.length)) {
          this.lengthControl$.next('All lengths');
        }
      });
    this.isWrapped$ = this.isWrappedControl$.pipe(distinctUntilChanged());
    this.applySavedOptions();
    this.length$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((length) => {
      this.store.dispatch(
        ngsGraphActions.params.options.update({
          id: this.documentID,
          graphId: 'codonDistribution',
          value: {
            options: {
              length,
            },
          },
        }),
      );
    });
    this.rawData$ = this.store.pipe(
      selectDataForNgsDocument<'codonDistribution'>(this.documentID, 'codonDistribution'),
      takeUntil(this.ngUnsubscribe),
      filter((data) => !!data),
    );
    this.data$ = combineLatest([this.rawData$, this.length$, this.isTable$]).pipe(
      map(([{ table, heatmap }, length, isTable]) => {
        const sharedInfo = {
          title: 'Codon Distribution Chart',
          xAxisTitle: 'Codon',
          yAxisTitle: length === 'All lengths' ? 'Length' : 'Position',
        };
        if (isTable) {
          return {
            ...sharedInfo,
            data: table,
            type: 'table',
          };
        }
        return {
          ...sharedInfo,
          data: heatmap,
          type: 'heatmap',
        };
      }),
    );

    const controlsBeforeFilter$ = this.lengths$.pipe(
      map((lengths) => ['All lengths', ...lengths]),
      map((lengths) => [
        {
          name: 'length',
          label: 'Lengths',
          type: GraphControlTypeEnum.SELECT,
          defaultOption:
            this.savedControls$?.value?.length &&
            lengths.includes(this.savedControls$?.value?.length)
              ? this.savedControls$?.value?.length
              : lengths[0],
          options: lengths.map((length) => {
            return {
              displayName: `${length}`,
              value: length,
            };
          }),
        },
      ]),
      map(
        (lengthControls) =>
          [
            ...lengthControls,
            {
              name: 'table',
              label: 'View Data as Table',
              type: GraphControlTypeEnum.CHECKBOX,
              defaultOption: this.savedControls$?.value?.table ?? false,
            },
            {
              name: 'wrapped',
              label: 'Wrapped',
              section: 'Layout',
              type: GraphControlTypeEnum.CHECKBOX,
              defaultOption: this.savedControls$?.value?.wrapped ?? false,
            },
          ] as GraphSidebarControl[],
      ),
    );
    combineLatest([controlsBeforeFilter$, this.isTable$, this.isWrapped$])
      .pipe(
        withLatestFrom(this.length$),
        map(([[controls, table, wrapped], length]) => {
          return controls
            .filter((c) => !table || c.name !== 'wrapped')
            .map((c) => {
              if (table && c.name === 'table') {
                return { ...c, defaultOption: true };
              } else if (c.name === 'length') {
                return { ...c, defaultOption: length };
              } else if (c.name === 'wrapped') {
                return { ...c, defaultOption: wrapped };
              }
              return c;
            }) as GraphSidebarControl[];
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.controls$);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.lengthControl$.complete();
    this.lengths$.complete();
    this.isTableControl$.complete();
    this.isWrappedControl$.complete();
  }

  onControlsChanged({ length, table, wrapped }: any) {
    this.lengths$
      .pipe(
        filter((len) => len.length > 0),
        take(1),
        map((lengths) => ['All lengths', ...lengths]),
      )
      .subscribe((lengths) => {
        const numberToTest = Number.isNaN(Number(length)) ? length : Number(length);
        this.lengthControl$.next(lengths.find((len) => len === numberToTest));
      });
    this.isTableControl$.next(table);
    this.isWrappedControl$.next(wrapped && !table);
  }

  exportAsTable() {
    combineLatest([this.selectedParams$.pipe(take(1)), this.rawData$]).subscribe(
      ([{ currentSelection, graphData }, { table }]) => {
        const fileName = `Codon Distribution ${currentSelection.selectedTable.value.displayName} ${currentSelection.options.value.length}`;
        const headers = table.cols.map((col) => col.field);
        const rows = table.rows.map((row) => headers.map((header) => row[header]));

        const CSVheader = headers.join(',');
        const CSVbody = rows.map((row: any) => row.join(',')).join('\n');

        DownloadBlobService.download(`${fileName}.csv`, CSVheader + '\n' + CSVbody);
      },
    );
  }
}
