@if (showRestoreOverlay$ | async) {
  <bx-ngs-table-restoring-overlay
    [selectedTable]="(uiIndexStateAndTable$ | async).table"
    [uiIndexState]="(uiIndexStateAndTable$ | async).state"
    [isGraph]="true"
  ></bx-ngs-table-restoring-overlay>
} @else {
  @if (data$ | async; as data) {
    <bx-stacked-column-chart
      class="amino-acid-chart"
      [animations]="animationsEnabled$ | async"
      [data]="data"
      [title]="'Amino Acid Distribution by Position'"
      [xAxisTitle]="'Position'"
      [yAxisTitle]="'Frequency'"
      [stacking]="'percent'"
      [showLegend]="showLegend$ | async"
      [showDataLabels]="showLabels$ | async"
    >
    </bx-stacked-column-chart>
  }
}
