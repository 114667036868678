import { ColDef, ColumnState, GridOptions } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import {
  filter,
  map,
  share,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import {
  DocumentDatasourceParams,
  DocumentServiceResource,
} from '../../../../../nucleus/services/documentService/document-service.resource';
import { IGridResourceResponse } from '../../../../../nucleus/services/models/response.model';
import { DocumentTableViewerService } from '../../../../core/document-table-service/document-table-viewer.service';
import {
  ProfileFeature,
  TablePreferencesProfile,
} from '../../../../core/user-settings/profiles/profiles.model';
import { IGetRowsRequestMinimal } from '../../../grid/datasource/grid.resource';
import { GridState, SortModel } from '../../../grid/grid.interfaces';
import { BaseReportWidgetComponent } from '../../base-report-widget.component';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { ReportWidgetComponent, ResultTableWidget } from '../../report.model';
import { ReadOnlyHeaderComponent } from './read-only-header-component';
import { selectProfilesForIdentifier } from 'src/app/core/user-settings/profiles/profiles.selectors';
import { AppState } from 'src/app/core/core.store';
import { selectUserID } from 'src/app/core/auth/auth.selectors';
import { fetchGridState } from '../../../../core/grid-state/grid-state.actions';
import { selectGridState } from '../../../../core/grid-state/grid-state.selectors';
import { v4 as uuid } from 'uuid';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { ReportWidgetTitleComponent } from '../../report-widget-title/report-widget-title.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { DocumentTableStateService } from '../../../../core/document-table-service/document-table-state/document-table-state.service';
import { DocumentTable } from '../../../../../nucleus/services/documentService/types';
import {
  DocumentTableUIIndexState,
  isQueryableIndexState,
} from '../../../../core/document-table-service/document-table-state/document-table-state';
import { NgsTableRestoringOverlayComponent } from '../../../../core/ngs/ngs-table-restoring-overlay/ngs-table-restoring-overlay.component';

@Component({
  selector: 'bx-result-table-widget',
  templateUrl: './result-table-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DocumentServiceResource, DocumentTableViewerService],
  standalone: true,
  imports: [
    MatLegacyButtonModule,
    MatIconModule,
    ReportWidgetTitleComponent,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    AsyncPipe,
    NgsTableRestoringOverlayComponent,
  ],
})
export class ResultTableWidgetComponent
  extends BaseReportWidgetComponent<ResultTableWidget>
  implements OnInit, ReportWidgetComponent
{
  @HostBinding('class') readonly hostClass = 'd-block';
  @Input() showControls = true;
  @Input() widget: ResultTableWidget;
  @Output() widgetReady = new EventEmitter();
  @Output() widgetRemoved = new EventEmitter<string>();

  tablePreferenceProfileIDSelectionControl = new FormControl<string>('default');
  topRowsOnly = new FormControl(true);
  numberOfRows = new FormControl(10, [Validators.min(1), Validators.max(500)]);

  /** Contains the list of all columns from the server, but no state. */
  columnDefinitions$: Observable<ColDef[]>;
  rows$: Observable<any[][]>;
  tablePreferenceProfiles$: Observable<TablePreferencesProfile[]>;
  selectedTablePreferenceProfile$: Observable<TablePreferencesProfile>;

  gridOptions: GridOptions;
  showMaxRowsWarningMessage$: Observable<boolean>;
  /** ID for binding labels to inputs */
  readonly uniqueID = uuid();

  private readonly maxRows = 500;
  uiIndexStateAndTable$: Observable<{
    indexState: DocumentTableUIIndexState;
    table: DocumentTable;
  }>;
  isTableDataReady$: Observable<boolean>;

  constructor(
    private documentServiceResource: DocumentServiceResource,
    private documentTableStateService: DocumentTableStateService,
    protected store: Store<AppState>,
  ) {
    super(store);
  }

  ngOnInit() {
    this.store.dispatch(fetchGridState({ id: this.widget.data.documentTable }));
    /** A fake profile that represents the current grid state. Used when the user does not select a profile. */
    const defaultTablePreference$: Observable<TablePreferencesProfile> = combineLatest([
      this.store.select(selectUserID).pipe(take(1)),
      this.store.select(selectGridState(this.widget.data.documentTable)),
    ]).pipe(
      map(([userID, gridState]) => ({
        id: 'default',
        identifier: null,
        feature: ProfileFeature.TABLE_PREFERENCES,
        name: 'Select Table Columns Profile',
        data: { columnsState: [...(gridState?.columnsState ?? [])] },
        isShared: false,
        userID,
      })),
      shareReplay(1),
    );

    this.initWidget$();
    this.setupAGGridOptions();

    this.tablePreferenceProfiles$ = combineLatest([
      this.store.select(
        selectProfilesForIdentifier('resultDocument', ProfileFeature.TABLE_PREFERENCES),
      ),
      defaultTablePreference$,
    ]).pipe(
      map(([profiles, defaultTablePreference]) => [defaultTablePreference, ...profiles]),
      shareReplay(1),
      takeUntil(this.ngUnsubscribe),
    );

    this.selectedTablePreferenceProfile$ = combineLatest([
      currentValueAndChanges(this.tablePreferenceProfileIDSelectionControl),
      this.tablePreferenceProfiles$,
    ]).pipe(
      map(([profileID, profiles]) => profiles.find((profile) => profile.id === profileID)),
      shareReplay(1),
      takeUntil(this.ngUnsubscribe),
    );

    currentValueAndChanges<boolean>(this.topRowsOnly)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((topRowsOnly) => {
        if (topRowsOnly) {
          this.numberOfRows.enable();
        } else {
          this.numberOfRows.disable();
        }
      });

    if (this.showControls) {
      this.patchWidgetData({ numberOfRows: 10 });

      combineLatest([
        currentValueAndChanges<boolean>(this.topRowsOnly),
        currentValueAndChanges<number>(this.numberOfRows),
      ])
        .pipe(
          map(([topRowsOnly, numberOfRows]) => {
            if (topRowsOnly) {
              return Math.max(Math.min(numberOfRows, this.maxRows), 1);
            }
            return this.maxRows;
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((numberOfRows) => {
          this.patchWidgetData({ numberOfRows });
        });
    } else {
      this.numberOfRows.setValue(this.widget.data.numberOfRows);
    }

    const uiIndexState$ = this.documentTableStateService.getUIIndexState(
      this.widget.data.documentID,
      this.widget.data.documentTable,
    );

    this.isTableDataReady$ = uiIndexState$.pipe(
      map((state) => isQueryableIndexState(state.currentIndexState)),
    );

    this.uiIndexStateAndTable$ = uiIndexState$.pipe(
      filter((state) => !isQueryableIndexState(state.currentIndexState)),
      withLatestFrom(
        this.documentTableStateService.getTable(
          this.widget.data.documentID,
          this.widget.data.documentTable,
        ),
      ),
      map(([uiState, documentTable]) => {
        return {
          indexState: uiState,
          table: documentTable,
        };
      }),
    );

    const data$ = this.selectedTablePreferenceProfile$.pipe(
      map((profile) => this.getSortModel((profile.data as GridState).columnsState)),
      switchMap((sortModel) => this.getTableData(sortModel)),
      share(),
    );

    this.columnDefinitions$ = this.selectedTablePreferenceProfile$.pipe(
      map((profile) =>
        (profile.data as GridState).columnsState.map((column: ColumnState) => ({
          colId: column.colId,
          field: column.colId,
          sort: column.sort,
          sortIndex: column.sortIndex,
          hide: column.hide,
          pinned: column.pinned,
          headerComponent: ReadOnlyHeaderComponent,
          suppressMenu: true,
          editable: this.showControls,
          suppressMovable: !this.showControls,
        })),
      ),
    );

    this.rows$ = data$.pipe(map((response) => response.data));

    this.showMaxRowsWarningMessage$ = data$.pipe(
      map((response) => this.showControls && response.metadata.total > 10),
    );
  }

  onRowDataChanged() {
    setTimeout(() => {
      this.widgetReady.emit();
    });
  }

  onColumnsChanged() {
    this.patchWidgetData({
      gridState: { columnsState: this.gridOptions.columnApi.getColumnState() },
    });
  }

  private getSortModel(columnState: ColumnState[]) {
    return columnState
      .filter((column) => column.sort)
      .sort((a, b) => a.sortIndex - b.sortIndex)
      .map((column) => ({ sort: column.sort, colId: column.colId }));
  }

  private getTableData(sortModel: SortModel[] = []): Observable<IGridResourceResponse<any>> {
    return this.widget$.pipe(
      switchMap((widget) => {
        const params: IGetRowsRequestMinimal = {
          startRow: 0,
          endRow: this.showControls ? 10 : (widget.data.numberOfRows ?? this.maxRows),
          sortModel: sortModel,
          filterModel: undefined,
        };
        const datasourceParams: DocumentDatasourceParams = {
          documentId: widget.data.documentID,
          documentTableName: widget.data.documentTable,
          filterModel: '',
          readonly: true,
        };
        return this.documentServiceResource.query(params, datasourceParams);
      }),
    );
  }

  private setupAGGridOptions() {
    this.gridOptions = {
      suppressHorizontalScroll: !this.showControls,
      suppressContextMenu: true,
      suppressRowClickSelection: true,
      headerHeight: 32,
      domLayout: this.showControls ? 'autoHeight' : 'print',
      maintainColumnOrder: true,
    };
  }
}
