@if (showRestoreOverlay$ | async) {
  <bx-ngs-table-restoring-overlay
    [selectedTable]="(uiIndexStateAndTable$ | async).table"
    [uiIndexState]="(uiIndexStateAndTable$ | async).state"
    [isGraph]="true"
  ></bx-ngs-table-restoring-overlay>
} @else {
  @if (selectionIsEmpty$ | async) {
    <bx-page-message>No rows selected</bx-page-message>
  } @else {
    @if (data$ | async; as data) {
      <bx-column-chart
        class="annotation-chart"
        [animations]="true"
        [data]="data.data"
        [title]="data.title | suffix: (titleSuffixForSelectionDependentGraphs$ | async) : ' '"
        [xAxisTitle]="data.xLabel"
        [yAxisTitle]="data.yLabel"
      >
      </bx-column-chart>
    }
  }
}
